import React, { Component } from 'react'
import Layout from '../layout/layout'
import Helmet from 'react-helmet'
import ScrollManager from '../helper/scrollManager'
import { easeFunction } from '../helper/variables'
import posed from 'react-pose'
import { connect } from 'react-redux'
import HeroBlock from '../components/serviceBlocks/heroBlock'
import HeadImage from '../components/common/headImage'

import './careers.css'
import { isBrowser, BrowserView } from 'react-device-detect'
import StudioBlock from '../components/aboutBlocks/studioBlock'
import Footer from '../components/common/footer'
import BenefitsBlock from '../components/careersBlock/benefitsBlock'
import BenefitsBg from '../components/careersBlock/benefitsBG'
import CareerList from '../components/careersBlock/careerList'
import { graphql } from 'gatsby'
import VisibilityWrapper from '../helper/visibilityWrapper'
import IntroBlock from '../components/aboutBlocks/introBlock'
import SectionIndicator from '../components/common/sectionIndicator'
import ReactGA from 'react-ga'

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
})

class Careers extends Component {
  state = {
    mainNode: null,
    scrollValue: 0,
    momentumScrollValue: 0,
    windowHeight: 0,
    onValues: false,
    hideValueTitle: true,
    triggerPointValues: 0,
    triggerPointIntro: 0,
    triggerPointStudio: 0,
    triggerPointFooter: 0,
    isReady: false,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
    this.sectionValues = React.createRef()
    this.sectionIntro = React.createRef()
    this.sectionStudio = React.createRef()
    this.sectionFooter = React.createRef()
  }

  componentDidMount() {
    ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    if (typeof window !== 'undefined')
      window.addEventListener('resize', this.getTriggerPoints)

    this.setState({
      mainNode: this.mainNode.current,
      isReady: true,
    })

    this.getTriggerPoints()
    this.props.backgroundWhite()
    this.props.noHomepageLoading()
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('resize', this.getTriggerPoints)

    this.props.backgroundWhite()
  }

  getTriggerPoints = () => {
    const windowHeight = typeof window !== 'undefined' && window.innerHeight
    const { momentumScrollValue } = this.state

    this.setState({
      windowHeight,
      triggerPointValues:
        momentumScrollValue +
        this.sectionValues.current.getBoundingClientRect().top,
      triggerPointIntro:
        momentumScrollValue +
        this.sectionIntro.current.getBoundingClientRect().top,
      triggerPointStudio:
        momentumScrollValue +
        this.sectionStudio.current.getBoundingClientRect().top,
      triggerPointFooter:
        momentumScrollValue +
        this.sectionFooter.current.getBoundingClientRect().top,
    })

    return windowHeight
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })

    if (value > this.state.triggerPointFooter - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
    } else if (value > this.state.triggerPointStudio - (isBrowser ? 75 : 55)) {
      this.props.backgroundWhite()
    } else if (
      value >
      this.state.triggerPointStudio - (isBrowser ? 800 : 400)
    ) {
      this.props.backgroundBlack()
    } else if (value > this.state.triggerPointIntro - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
      this.setState({
        onValues: false,
      })
    } else if (value > this.state.triggerPointIntro - (isBrowser ? 800 : 0)) {
      this.props.backgroundWhite()
      this.setState({
        onValues: false,
      })
    } else if (value > this.state.triggerPointValues - (isBrowser ? 700 : 0)) {
      this.setState({
        onValues: true,
      })
    } else {
      this.setState({
        onValues: false,
      })
    }
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  render() {
    const page = this.props.data.contentfulCareersLanding
    const careers = this.props.data.allCareerPostings.nodes
    const {
      momentumScrollValue,
      onValues,
      triggerPointValues,
      hideValueTitle,
      isReady,
    } = this.state
    const { theme } = this.props

    // console.log(
    //   '[DEPARTMENTS]',
    //   this.props.data.allContentfulDepartments.edges.map(({ node }) => node)
    // )
    // console.log('[POSTINGS]', this.props.data.allCareerPostings.nodes)

    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>{page.metaData.metaTitle}</title>
          <meta name="description" content={page.metaData.description} />
          <meta name="keywords" content={page.metaData.keywords} />
          <meta property="og:title" content={page.metaData.metaTitle} />
          <meta property="og:description" content={page.metaData.description} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={
              page.metaData.ogImage
                ? `https:${page.metaData.ogImage.file.url}`
                : 'https://www.baunfire.com/ogimage.jpg'
            }
          />
          <meta property="og:url" content="https://www.baunfire.com/careers" />
          <link rel="canonical" href="https://www.baunfire.com/careers" />
        </Helmet>
        <PageTransition>
          <ScrollManager
            page={this.state.mainNode}
            scrollValue={this.state.scrollValue}
            updateScrollValue={this.updateScrollValue}
            updateMomentumScrollValue={this.updateMomentumScrollValue}
          >
            <section className="page-careers-landing">
              <HeroBlock
                page={page}
                momentumScrollValue={momentumScrollValue}
              />
              <HeadImage
                fluid={page.headImage.fluid}
                momentumScrollValue={momentumScrollValue}
              />
              <section
                className="section-career-list"
                ref={this.sectionCareers}
              >
                <CareerList page={page} careers={careers} />
              </section>
              <section className="section-values" ref={this.sectionValues}>
                <BenefitsBlock
                  page={page}
                  momentumScrollValue={momentumScrollValue}
                />
              </section>
              <VisibilityWrapper partialVisibility={true}>
                {({ isVisible }) => {
                  return (
                    <div
                      className={`area-about-intro ${isVisible && 'appear'}`}
                    >
                      <div className="content-container">
                        <section
                          className="section-about-intro section-block"
                          ref={this.sectionIntro}
                        >
                          <IntroBlock
                            data={page}
                            momentumScrollValue={momentumScrollValue}
                          />
                        </section>
                      </div>
                    </div>
                  )
                }}
              </VisibilityWrapper>
              <section className="section-studio" ref={this.sectionStudio}>
                <StudioBlock page={page} theme={theme} />
              </section>
            </section>
            <section ref={this.sectionFooter}>
              <Footer />
            </section>
          </ScrollManager>
          <BrowserView>
            <BenefitsBg
              page={page}
              momentumScrollValue={momentumScrollValue}
              onValues={onValues}
              hideValueTitle={hideValueTitle}
              startingPoint={triggerPointValues}
              isReady={isReady}
            />

            <SectionIndicator theme={theme}>
              {page.heroRibbonText}
            </SectionIndicator>
          </BrowserView>
        </PageTransition>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    contentfulCareersLanding(title: { eq: "Careers landing" }) {
      metaData {
        title
        metaTitle
        description
        keywords
      }
      heroRibbonText
      heroHeadline
      heroDescription
      headImage {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      careerListTitle
      benefitsTitle
      benefits {
        id
        title
        description
      }
      introRibbonText
      introText
      studioTitle
      studioDescription
      studioImages {
        id
        fluid(maxWidth: 900, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    allContentfulDepartments {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    allContentfulCareers(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          slug
          title
          metaData {
            title
            metaTitle
            description
            keywords
          }
          department
        }
      }
    }
    allCareerPostings: allPostings {
      nodes {
        id
        sourceId: id__normalized
        text
        categories {
          department
        }
      }
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    backgroundRed: () => dispatch({ type: 'BACKGROUND_RED' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedAbout = connect(mapStateToProps, mapDispatchToProps)(Careers)

export default ConnectedAbout
